import { ApiModules, apiUrl } from "src/api";
import { ResolutionString } from "src/charting_library/charting_library";
import ResponseHandler from "src/state/network/ResponseHandler";
import {
  AccTerminalProps,
  EditedFloatingOrderDTO,
  FeeRations,
  FloatingFreezeRequest,
  IAccountBalance,
  IAccountCred,
  ICEXPairInfo,
  ICandleBar,
  IClientExchangeInfo,
  ICreatedFloatingOrderDTO,
  ICreatedLimitOrderStatus,
  ICreatedStopOrder,
  IExchangeOrderBook,
  IExtendedLimitOrderStatusDTO,
  IFloatingOrder,
  IFloatingOrderInfoDTO,
  IHistoryTransfer,
  IMakeVolume,
  IOurPlacedLimitOrder,
  IStopOrder,
  IStopOrderStatus,
  ITradeHistoryOrder,
  ITransfer,
  LimitOrderResponse,
  LiquidityMode,
  OpenLimitOrderTerminalProps,
  OrderTerminalProps,
  PlaceLiquidityRequestData,
  PublicTerminalProps,
  PublicTerminalWithRangeProps,
  Volume24h,
} from "./types";

interface MakeVolumeRequestProps extends IMakeVolume {}

export const makeVolume = async (data: MakeVolumeRequestProps) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Terminal, "make-volume"),
    method: "POST",
    data,
  });

interface PlaceLiquidityRequestProps {
  data: PlaceLiquidityRequestData;
  mode: LiquidityMode;
}

export const placeLiquidity = async ({ data, mode }: PlaceLiquidityRequestProps) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Terminal, `place-liquidity?mode=${mode}`),
    method: "POST",
    data,
  });

interface FetchOrderBookRequestProps extends PublicTerminalProps {}

export interface FetchOrderBookResponse {
  data: IExchangeOrderBook;
}

export const fetchOrderBook = async (data: FetchOrderBookRequestProps) =>
  ResponseHandler.handler<FetchOrderBookResponse>({
    url: apiUrl(ApiModules.Terminal, "/get-order-book"),
    method: "POST",
    data,
  });

interface FetchTradeHistoryRequestProps extends PublicTerminalProps {}

type FetchTradeHistoryResponse = ITradeHistoryOrder[];

export const fetchTradeHistory = async (data: FetchTradeHistoryRequestProps) =>
  ResponseHandler.handler<FetchTradeHistoryResponse>({
    url: apiUrl(ApiModules.Terminal, "/get-trades"),
    method: "POST",
    data,
  });

interface FetchLimitOrdersRequestProps extends AccTerminalProps {}

interface FetchLimitOrdersResponse {
  data: ICreatedLimitOrderStatus[];
}

export const fetchLimitOrders = async (data: FetchLimitOrdersRequestProps) =>
  ResponseHandler.handler<FetchLimitOrdersResponse>({
    url: apiUrl(ApiModules.Terminal, "get-my-open-orders"),
    method: "POST",
    data,
  });

export interface FetchLimitOrderRequestProps extends OrderTerminalProps {}

interface FetchLimitOrderResponse {
  data: ICreatedLimitOrderStatus;
}

export const fetchLimitOrder = async (data: FetchLimitOrderRequestProps) =>
  ResponseHandler.handler<FetchLimitOrderResponse>({
    url: apiUrl(ApiModules.Terminal, "get-order"),
    method: "POST",
    data,
  });

interface GetAllOpenOrdersRequestProps {
  pair: string;
  account_uuids: string[];
}

interface GetAllOpenOrdersResponse {
  buy_orders: IOurPlacedLimitOrder[];
  sell_orders: IOurPlacedLimitOrder[];
}

export const getAllOpenOrders = (data: GetAllOpenOrdersRequestProps) =>
  ResponseHandler.handler<GetAllOpenOrdersResponse>({
    url: apiUrl(ApiModules.Terminal, "/get-all-open-orders"),
    method: "POST",
    data,
  });

export interface LimitOrderRequestProps {
  order_id: string;
  account_uuid: string;
  pair: string;
}

interface CancelOrderResponse {}

export const cancelOrder = async (data: LimitOrderRequestProps) =>
  ResponseHandler.handler<CancelOrderResponse>({
    url: apiUrl(ApiModules.Terminal, "cancel"),
    method: "POST",
    data,
  });

export interface CancelManyOrderRequestProps extends AccTerminalProps {
  order_ids: string[];
}

interface CancelOrderResponse {}

export const cancelManyOrder = async (data: CancelManyOrderRequestProps) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Terminal, "cancel-many"),
    method: "POST",
    data,
  });

interface CancelAllOrdersRequestProps extends AccTerminalProps {}

interface CancelAllOrdersResponse {}

export const cancelAllOrders = async (data: CancelAllOrdersRequestProps) =>
  ResponseHandler.handler<CancelAllOrdersResponse>({
    url: apiUrl(ApiModules.Terminal, "cancel-all"),
    method: "POST",
    data,
  });

interface OpenBuyOrderRequestProps extends OpenLimitOrderTerminalProps {}

export interface OpenBuyOrderResponse extends LimitOrderResponse {}

export const openBuyOrder = async (data: OpenBuyOrderRequestProps) =>
  ResponseHandler.handler<OpenBuyOrderResponse>({
    url: apiUrl(ApiModules.Terminal, "buy"),
    method: "POST",
    data,
  });

interface OpenSellOrderRequestProps extends OpenLimitOrderTerminalProps {}

interface OpenSellOrderResponse extends LimitOrderResponse {}

export const openSellOrder = async (data: OpenSellOrderRequestProps) =>
  ResponseHandler.handler<OpenSellOrderResponse>({
    url: apiUrl(ApiModules.Terminal, "sell"),
    method: "POST",
    data,
  });

interface FetchBalanceRequestProps extends Pick<AccTerminalProps, "account_uuid"> {}

interface FetchBalanceResponse {
  data: IAccountBalance[];
}

export const fetchBalance = async (data: FetchBalanceRequestProps) =>
  ResponseHandler.handler<FetchBalanceResponse>({
    url: apiUrl(ApiModules.Terminal, "get-balance"),
    method: "POST",
    data,
  });

interface TransferRequestProps extends ITransfer {}

interface TransferResponse {}

export const transfer = async (data: TransferRequestProps) =>
  ResponseHandler.handler<TransferResponse>({
    url: apiUrl(ApiModules.Terminal, "transfer"),
    method: "POST",
    data,
  });

interface GetCandlesRequestProps {
  exchange: string;
  pair: string;
  from: number;
  to: number;
  resolution: ResolutionString;
  countBack?: number;
  account_uuid?: string;
}

interface GetCandlesResponse {
  data: ICandleBar[];
}

export const getCandles = async ({
  exchange,
  pair,
  from,
  to,
  resolution = "5" as ResolutionString,
  countBack,
  account_uuid,
}: GetCandlesRequestProps) => {
  const minCandles = countBack ? `&countBack=${countBack}` : "";

  return ResponseHandler.handler<GetCandlesResponse>({
    url: apiUrl(
      ApiModules.Terminal,
      `/get-candles?symbol=${pair}&from=${from}&to=${to}${minCandles}&resolution=${resolution}`
    ),
    method: "POST",
    data: { exchange, account_uuid },
  });
};

export interface GetClientsResponse {
  data: IClientExchangeInfo[];
}

export const getClients = async () =>
  ResponseHandler.handler<GetClientsResponse>({
    url: apiUrl(ApiModules.Terminal, "/clients"),
    method: "GET",
  });

interface GetPairsRequestProps {
  exchange: string;
}

interface GetPairsResponse {
  data: ICEXPairInfo[];
}

export const getPairs = async (data: GetPairsRequestProps) =>
  ResponseHandler.handler<GetPairsResponse>({
    url: apiUrl(ApiModules.Terminal, "/get-pairs"),
    method: "POST",
    data,
  });

interface GetStopOrdersRequestProps extends AccTerminalProps {}

interface GetStopOrdersResponse {
  data: ICreatedStopOrder[];
}

export const getStopOrders = async ({ account_uuid, pair }: GetStopOrdersRequestProps) =>
  ResponseHandler.handler<GetStopOrdersResponse>({
    url: apiUrl(ApiModules.Terminal, "algo/stop/orders"),
    method: "POST",
    data: {
      account_uuid,
      filters: [{ field: "pair", op: "eq", value: pair }],
    },
  });

interface GetStopOrderStatusRequestProps extends OrderTerminalProps {}

interface GetStopOrderStatusResponse {
  data: IStopOrderStatus;
}

export const getStopOrderStatus = async (data: GetStopOrderStatusRequestProps) =>
  ResponseHandler.handler<GetStopOrderStatusResponse>({
    url: apiUrl(ApiModules.Terminal, "algo/stop/status"),
    method: "POST",
    data,
  });

interface CancelStopOrderRequestProps extends OrderTerminalProps {}

interface CancelStopOrderResponse {}

export const cancelStopOrder = async (data: CancelStopOrderRequestProps) =>
  ResponseHandler.handler<CancelStopOrderResponse>({
    url: apiUrl(ApiModules.Terminal, "algo/stop/cancel"),
    method: "POST",
    data,
  });

export interface OpenStopOrderRequestProps {
  account_uuid: string;
  stopOrder: IStopOrder;
}

export interface OpenStopOrderResponse {
  algo_order_id: number;
}

export const openStopOrder = async ({ account_uuid, stopOrder }: OpenStopOrderRequestProps) =>
  ResponseHandler.handler<OpenStopOrderResponse>({
    url: apiUrl(ApiModules.Terminal, "algo/stop/create"),
    method: "POST",
    data: { account_uuid, ...stopOrder },
  });

interface FetchFeeRationsRequestProps {
  account_uuid: string;
  pair: string;
}

interface FetchFeeRationsResponse {
  data: FeeRations;
}

export const fetchFeeRations = async (data: FetchFeeRationsRequestProps) =>
  ResponseHandler.handler<FetchFeeRationsResponse>({
    url: apiUrl(ApiModules.Terminal, "get-fee-ratios"),
    method: "POST",
    data,
  });

interface FetchVolume24RequestProps extends PublicTerminalProps {}

interface FetchVolume24Response {
  data: Volume24h;
}

export const fetchVolume24 = async (data: FetchVolume24RequestProps) =>
  ResponseHandler.handler<FetchVolume24Response>({
    url: apiUrl(ApiModules.Terminal, "get-candle-24h"),
    method: "POST",
    data,
  });

interface FetchAccountHistoryRequestProps extends PublicTerminalWithRangeProps {}

interface FetchAccountHistoryResponse {
  data: ICreatedLimitOrderStatus[];
}

export const fetchAccountHistory = async (data: FetchAccountHistoryRequestProps) =>
  ResponseHandler.handler<FetchAccountHistoryResponse>({
    url: apiUrl(ApiModules.Terminal, "get-trade-history"),
    method: "POST",
    data,
  });

export interface FetchTransferHistoryRequestProps extends PublicTerminalWithRangeProps {
  currency: string;
}

interface FetchTransferHistoryResponse {
  data: IHistoryTransfer[];
}

export const fetchTransferHistory = async (data: FetchTransferHistoryRequestProps) =>
  ResponseHandler.handler<FetchTransferHistoryResponse>({
    url: apiUrl(ApiModules.Terminal, "get-transfers"),
    method: "POST",
    data,
  });

export interface GetFloatingOrdersRequest {
  account_uuid: string;
  pair: string;
}

interface GetFloatingOrdersResponse {
  data: ICreatedFloatingOrderDTO[];
}

export const getFloatingOrders = ({ account_uuid, pair }: GetFloatingOrdersRequest) =>
  ResponseHandler.handler<GetFloatingOrdersResponse>({
    url: apiUrl(ApiModules.Terminal, "algo/floating/orders"),
    method: "POST",
    data: {
      account_uuid,
      filters: [{ field: "pair", op: "eq", value: pair }],
    },
  });

export interface OpenFloatingOrderRequest {
  data: IFloatingOrder;
}

interface OpenFloatingOrderResponse {
  algo_order_id: number;
  isError: boolean;
  message: string;
}

export const openFloatingOrder = ({ data }: OpenFloatingOrderRequest) =>
  ResponseHandler.handler<OpenFloatingOrderResponse>({
    url: apiUrl(ApiModules.Terminal, "algo/floating/create"),
    method: "POST",
    data,
  });

export interface GetFloatingOrderStatusRequest {
  account_uuid: string;
  order_id: string;
}

interface GetFloatingOrderStatusResponse {
  data: IFloatingOrderInfoDTO;
  isError: boolean;
  message: string;
}

export const getFloatingOrderStatus = (data: GetFloatingOrderStatusRequest) =>
  ResponseHandler.handler<GetFloatingOrderStatusResponse>({
    url: apiUrl(ApiModules.Terminal, "algo/floating/status"),
    method: "POST",
    data,
  });

export interface CancelFloatingOrderRequest extends GetFloatingOrderStatusRequest {}

interface CancelFloatingOrderResponse {}

export const cancelFloatingOrder = (data: CancelFloatingOrderRequest) =>
  ResponseHandler.handler<CancelFloatingOrderResponse>({
    url: apiUrl(ApiModules.Terminal, "algo/floating/cancel"),
    method: "POST",
    data,
  });

interface CancelManyFloatingOrderRequest {
  account_uuid: string;
  order_ids: string[];
}

export const cancelManyFloatingOrder = (data: CancelManyFloatingOrderRequest) =>
  ResponseHandler.handler<CancelFloatingOrderResponse>({
    url: apiUrl(ApiModules.Terminal, "algo/floating/cancel-many"),
    method: "POST",
    data,
  });

interface CancelAllFloatingOrderRequest {
  account_uuid: string;
  pair: string;
}

export const cancelAllFloatingOrder = (data: CancelAllFloatingOrderRequest) =>
  ResponseHandler.handler<CancelFloatingOrderResponse>({
    url: apiUrl(ApiModules.Terminal, "algo/floating/cancel-all"),
    method: "POST",
    data,
  });

type GetPlacedOrdersFromMarketRequest = IAccountCred[];

export const getPlacedOrdersFromMarket = (data: GetPlacedOrdersFromMarketRequest) =>
  ResponseHandler.handler<IExtendedLimitOrderStatusDTO[]>({
    url: apiUrl(ApiModules.Terminal, "get-markets-orders"),
    method: "POST",
    data,
  });

type ExtendedLimitOrdersRequest = IAccountCred[];

interface FetchExtendedLimitOrdersResponse {
  data: IExtendedLimitOrderStatusDTO[];
}

export const fetchExtendedLimitOrders = async (data: ExtendedLimitOrdersRequest) =>
  ResponseHandler.handler<FetchExtendedLimitOrdersResponse>({
    url: apiUrl(ApiModules.Terminal, "get-all-open-orders-detail"),
    method: "POST",
    data,
  });

export const cancelLimitOrdersByAcc = async (data: LimitOrderRequestProps[]) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Terminal, "cancel-many-by-accounts"),
    method: "POST",
    data,
  });

export const floatingOrderFreeze = async (data: FloatingFreezeRequest) =>
  ResponseHandler.handler({
    url: apiUrl(ApiModules.Terminal, "algo/floating/freeze"),
    method: "POST",
    data,
  });

export const editFloatingOrder = (data: EditedFloatingOrderDTO) =>
  ResponseHandler.handler<OpenFloatingOrderResponse>({
    url: apiUrl(ApiModules.Terminal, "algo/floating/edit"),
    method: "POST",
    data,
  });
