import { ApiModules, apiUrl } from "src/api";
import { DEXCommonExchangeVersion } from "src/modules/bots";
import { ApiResponse } from "src/modules/network";
import ResponseHandler from "src/state/network/ResponseHandler";

export interface ChainInfoItem {
  chain_id: string;
  color: string;
  dexscreener_alias: string;
  id: string;
  name: string;
  pic: string;
  scanner: string;
  symbol: string;
  bundle_support: boolean;
}

export type GetChainsInfoResponse = ChainInfoItem[];

export const getChainsInfo = async () =>
  ResponseHandler.handler<GetChainsInfoResponse>(
    {
      url: apiUrl(ApiModules.DEXNE, "chainsinfo"),
      method: "GET",
    },
    "getChainsInfoDEXNE"
  );

export interface SupportedExchange {
  version: DEXCommonExchangeVersion;
  pool_percent: number[];
  name: string;
  chain_id: string;
}

export type SupportedExchanges = {
  data: Record<string, { exchanges: SupportedExchange[] }>;
};

export interface GetSupportedExchangesResponse extends SupportedExchanges {}

export const getSupportedExchanges = async (): Promise<
  ApiResponse<GetSupportedExchangesResponse>
> =>
  ResponseHandler.handler<GetSupportedExchangesResponse>(
    {
      url: apiUrl(ApiModules.DEXNE, "exchanges"),
      method: "GET",
    },
    "getSupportedExchangesDEXNE"
  );
