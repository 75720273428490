import { LanguageCode } from "src/charting_library/charting_library";
import { entries } from "../utils";

export interface PathUrlParams {
  baseUrl: string;
  prefix: string;
  url: string;
}

const SLASHES_TRIM_REGEXP = /^\/+|\/+$/g;

/**
 * removes any leading and trailing slashes in string
 * @param str - string to trim
 * @returns string without start/end slashes
 */

export const trimStartEndSlashes = (str: string) => str.replace(SLASHES_TRIM_REGEXP, "");

// eslint-disable-next-line no-useless-escape
const LANGUAGE_REGEX = /[\?&]lang=([^&#]*)/;

/**
 *
 * @returns language code from decoded URL param
 */
export const getLanguageFromURL = (): LanguageCode | null => {
  const regex = LANGUAGE_REGEX;
  const results = regex.exec(window.location.search);

  return results === null
    ? null
    : (decodeURIComponent(results[1].replace(/\+/g, " ")) as LanguageCode);
};

export type QueryParams<Params extends string> = { [K in Params]?: string | number };

export const buildQueryParamsString = <Params extends string>(params: QueryParams<Params>) => {
  const nonEmptyParams = entries(params).filter(([, value]) => Boolean(value)) as Array<
    [string, string]
  >;
  const searchParams = new URLSearchParams(nonEmptyParams);
  return `${searchParams}`;
};

export const buildQueryUrl = <Params extends string>(
  baseUrl: string,
  params?: QueryParams<Params>
) => {
  if (!params) return baseUrl;
  const paramsString = buildQueryParamsString(params);

  if (!paramsString) return baseUrl;

  return `${baseUrl}?${paramsString}`;
};

export const lastUrlSegment = (url: string) => {
  const segments = new URL(url).pathname.split("/");
  // Handle potential trailing slash i.e /segment/? or empty segments with filter
  const lastSegment = segments.filter(Boolean).pop();
  return lastSegment;
};
/**
 * method for generating the full path of the request
 * @param baseUrl - host path
 * @param prefix - api version indication
 * @param url - final path to the resource
 * @returns
 */
export const getRequestUrl = ({ baseUrl, prefix, url }: PathUrlParams) => {
  const preparedPrefix = prefix ? `${trimStartEndSlashes(prefix)}/` : "";
  return `${baseUrl}/${preparedPrefix}${trimStartEndSlashes(url)}`;
};

/**
 * returns the root URL of the application
 * @returns root URL
 */
export const getRootURL = () => window.location.origin;
