const HTTPS_PROTOCOL = "https://";
const WS_PROTOCOL = "wss://";

const baseUrl = import.meta.env.VITE_APP_API_URL;

export const availableOriginsUrl = import.meta.env.VITE_APP_AVAILABLE_ORIGINS_URL;

export const enableApiOriginSelector = import.meta.env.VITE_APP_ENABLE_API_ORIGIN_SELECTOR;

let backendUrl = baseUrl;

if (enableApiOriginSelector) {
  const storedUrl = localStorage.getItem("api_base_url");

  if (storedUrl) {
    backendUrl = storedUrl;
  }
}

export const apiUrl = HTTPS_PROTOCOL + backendUrl;

export const wsUrl = WS_PROTOCOL + backendUrl;

export const googleClientId = import.meta.env.VITE_APP_GOOGLE_CLIENT_ID;

export const isDevEnv = () => !import.meta.env.MODE || import.meta.env.MODE === "development";

export const enableStories = import.meta.env.VITE_APP_ENABLE_STORIES;

export const BUILD_BASE_URL = import.meta.env.BASE_URL;
