import { ChainInfoItem, GetChainsInfoResponse } from "src/api/bots/DEX_NE/chains";
import { trimStartEndSlashes } from "src/helpers/url";
import { Mapper } from "src/helpers/utils";
import { ChainInfo } from "./types";

const chainInfoItemToChainInfo: Mapper<ChainInfoItem, ChainInfo> = ({
  name,
  pic,
  symbol,
  scanner,
  color,
  dexscreener_alias,
  bundle_support,
}) => {
  const scannerBaseUrl = trimStartEndSlashes(scanner);
  return {
    txScanner: `${scannerBaseUrl}/tx`,
    addressScanner: `${scannerBaseUrl}/address`,
    dexscreenerName: dexscreener_alias,
    name,
    native: symbol,
    abbr: symbol,
    icon: pic,
    color,
    bundleSupport: bundle_support,
  };
};

export const chainsInfoRespToChainInfos: Mapper<GetChainsInfoResponse, ChainInfo[]> = (resp) =>
  resp.map(chainInfoItemToChainInfo);
