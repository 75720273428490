import { useEffect, useMemo, useState } from "react";
import { toParseJSON } from "src/helpers/string";
import { Selector } from "../../shared/Forms/Selectors";
import { StringSelectorValue } from "../../../modules/shared";
import { availableOriginsUrl } from "../../../environment/env";

interface Origin {
  hostname: string;
  namespace: string;
  app: string;
}

export const OriginSelect = () => {
  const [options, setOptions] = useState<StringSelectorValue[]>([]);
  const selectedOrigin = localStorage.getItem("api_base_url");

  const handleOriginChange = (option: StringSelectorValue | null) => {
    if (option?.value) {
      localStorage.setItem("api_base_url", option.value);
      window.location.reload();
    }
  };

  const selectedOption = useMemo(
    () => options.find((option) => option.value === selectedOrigin),
    [options, selectedOrigin]
  );

  useEffect(() => {
    fetch(availableOriginsUrl)
      .then((response: Response) => response.text())
      .then((responseText) => {
        const data = toParseJSON<Origin[]>(responseText);

        if (Array.isArray(data))
          setOptions(data.map((item) => ({ label: item.namespace, value: item.hostname })));
      });
  }, []);

  return (
    <div style={{ width: 200, marginRight: "auto" }}>
      <Selector
        onChange={handleOriginChange}
        placeholder="Select origin"
        value={selectedOption}
        options={options}
      />
    </div>
  );
};
