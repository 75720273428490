import { PrimitiveSelectorValue } from "src/helpers/forms/selectors";
import { DEXCommonExchangeVersion } from "src/modules/bots";
import { DEXNECreateSettings } from "../shared/types";

export const GAS_LIMIT_DEFAULT = 200000;

export const INITIAL_DEXNE_CREATE_SETTINGS: DEXNECreateSettings = {
  base_data: {
    party: "",
    network: "",
    pair: "",
    quote: "",
    base: "",
    exchange: "",
    version: "",
  },
  main_data: {
    bot_name: "",
    limit_executors_num: 3,
    volume_executors_num: 3,
    counter_executors_num: 3,
    tt_buy_fee: 0,
    tt_sell_fee: 0,
    gas_limit: "",
    stable: "",
    stable_pool: "",
  },
  volume_data: {
    on: false,
    bundle: false,
    period: 5,
    min_trades: 3,
    max_trades: 5,
    buy_percent: 50,
    min_amount: 1,
    max_amount: 3,
    slippage: 0.5,
    gas_mult: 0,
    gas_price_limit: 0,
  },
  limit_data: {
    on: false,
    slippage: 0.5,
    gas_mult: 0,
    gas_price_limit: 0,
    data: [],
  },
  counter_data: {
    on: false,
    black_listed_wallets: [],
    data: [],
    gas_mult: 0,
    gas_price_limit: 0,
    slippage: 0.5,
  },
};

interface DEXNEVersionOptions extends PrimitiveSelectorValue<DEXCommonExchangeVersion> {}

export const DEX_VERSION_OPTIONS: DEXNEVersionOptions[] = [
  { value: DEXCommonExchangeVersion.V2, label: "v2" },
  { value: DEXCommonExchangeVersion.V3, label: "v3" },
];
